"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function MarketAmenityPagesFilterResultsButton({
  as: _Component = _Builtin.Block,

  linkProps = {
    href: "#",
  },
}) {
  return (
    <_Component className="filter-results-button" tag="div">
      <_Builtin.Block className="hide-mobile-landscape" tag="div">
        <_Builtin.Link
          className="button-copy"
          button={false}
          block=""
          options={linkProps}
        >
          {"Filter Results"}
        </_Builtin.Link>
      </_Builtin.Block>
      <_Builtin.Block
        className="show-mobile-landscape"
        id="w-node-_528b0b7a-b231-71d6-dcf8-ab572ea4aedd-2ea4aed9"
        tag="div"
      >
        <_Builtin.Link
          className="button is-filter"
          id="w-node-_528b0b7a-b231-71d6-dcf8-ab572ea4aede-2ea4aed9"
          button={false}
          block="inline"
          options={linkProps}
        >
          <_Builtin.HtmlEmbed
            className="icon-embed-xsmall-2"
            value="%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20aria-hidden%3D%22true%22%20role%3D%22img%22%20class%3D%22iconify%20iconify--ic%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20preserveaspectratio%3D%22xMidYMid%20meet%22%20viewbox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M10%2018h4v-2h-4zM3%206v2h18V6zm3%207h12v-2H6z%22%2F%3E%3C%2Fsvg%3E"
          />
        </_Builtin.Link>
      </_Builtin.Block>
    </_Component>
  );
}
