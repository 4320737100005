"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function SearchInputSearchButton({ as: _Component = _Builtin.Link }) {
  return (
    <_Component
      className="search_search-button---adv-search-page"
      button={false}
      block="inline"
      options={{
        href: "#",
      }}
    >
      <_Builtin.HtmlEmbed
        className="icon-embed-xxsmall---adv-search-page"
        value="%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20aria-hidden%3D%22true%22%20role%3D%22img%22%20class%3D%22iconify%20iconify--fe%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20preserveaspectratio%3D%22xMidYMid%20meet%22%20viewbox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22currentColor%22%20fill-rule%3D%22evenodd%22%20d%3D%22m16.325%2014.899l5.38%205.38a1.008%201.008%200%200%201-1.427%201.426l-5.38-5.38a8%208%200%201%201%201.426-1.426M10%2016a6%206%200%201%200%200-12a6%206%200%200%200%200%2012%22%2F%3E%3C%2Fsvg%3E"
      />
    </_Component>
  );
}
