"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function ExploreCard({
  as: _Component = _Builtin.Link,

  link = {
    href: "#",
  },

  image = "https://uploads-ssl.webflow.com/656bce4e0dae8369c9788229/656be1165608e1e4ed4e001a_Rectangle-83.png",
  text = "Apartment Homes",
  altText,
}) {
  return (
    <_Component
      className="explore_card"
      button={false}
      block="inline"
      options={link}
    >
      <_Builtin.Block className="explore_card-image-wrapper" tag="div">
        <_Builtin.Image
          className="explore_card-image"
          loading="lazy"
          width="Auto"
          height="Auto"
          src={image}
        />
      </_Builtin.Block>
      <_Builtin.Block className="text-size-large text-color-blue" tag="div">
        {text}
      </_Builtin.Block>
    </_Component>
  );
}
