"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function CardIconEvCharging({ as: _Component = _Builtin.Block }) {
  return (
    <_Component className="amenity---icon" tag="div" title="EV Charging">
      <_Builtin.HtmlEmbed
        className="html-embed-2"
        value="%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20576%20512%22%20aria-labelledby%3D%22title%22%3E%3E%0A%20%20%3Ctitle%20id%3D%22title%22%20lang%3D%22en%22%3EThis%20community%20has%20EV%20Charging%3C%2Ftitle%3E%0A%20%20%3Cpath%20fill%3D%22CurrentColor%22%20d%3D%22M96%200C60.7%200%2032%2028.7%2032%2064V448c-17.7%200-32%2014.3-32%2032s14.3%2032%2032%2032H320c17.7%200%2032-14.3%2032-32s-14.3-32-32-32V304h16c22.1%200%2040%2017.9%2040%2040v32c0%2039.8%2032.2%2072%2072%2072s72-32.2%2072-72V252.3c32.5-10.2%2056-40.5%2056-76.3V144c0-8.8-7.2-16-16-16H544V80c0-8.8-7.2-16-16-16s-16%207.2-16%2016v48H480V80c0-8.8-7.2-16-16-16s-16%207.2-16%2016v48H432c-8.8%200-16%207.2-16%2016v32c0%2035.8%2023.5%2066.1%2056%2076.3V376c0%2013.3-10.7%2024-24%2024s-24-10.7-24-24V344c0-48.6-39.4-88-88-88H320V64c0-35.3-28.7-64-64-64H96zM216.9%2082.7c6%204%208.5%2011.5%206.3%2018.3l-25%2074.9H256c6.7%200%2012.7%204.2%2015%2010.4s.5%2013.3-4.6%2017.7l-112%2096c-5.5%204.7-13.4%205.1-19.3%201.1s-8.5-11.5-6.3-18.3l25-74.9H96c-6.7%200-12.7-4.2-15-10.4s-.5-13.3%204.6-17.7l112-96c5.5-4.7%2013.4-5.1%2019.3-1.1z%22%2F%3E%3C%2Fsvg%3E"
      />
    </_Component>
  );
}
