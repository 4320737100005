"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function AdvSearchOpenCloseMapToggle({
  as: _Component = _Builtin.Block,
  showCloseButton = true,
  toggleMapButtonProps = {},
}) {
  return (
    <_Component className="map-button-open-close-component" tag="div">
      <_Builtin.Link
        className="map-button-show-map-icon"
        button={false}
        aria-label="Show Interactive Map"
        block="inline"
        options={{
          href: "#",
        }}
        {...toggleMapButtonProps}
      >
        <_Builtin.HtmlEmbed
          className="icon-embed-xsmall mobile-small blue"
          value="%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20aria-hidden%3D%22true%22%20role%3D%22img%22%20class%3D%22iconify%20iconify--iconoir%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20preserveaspectratio%3D%22xMidYMid%20meet%22%20viewbox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22none%22%20stroke%3D%22currentColor%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%221.5%22%20d%3D%22m9%2019l-5.21%201.737a.6.6%200%200%201-.79-.57V5.433a.6.6%200%200%201%20.41-.569L9%203m0%2016l6%202m-6-2V3m6%2018l5.59-1.863a.6.6%200%200%200%20.41-.57V3.832a.6.6%200%200%200-.79-.569L15%205m0%2016V5m0%200L9%203%22%2F%3E%3C%2Fsvg%3E"
        />
      </_Builtin.Link>
      {showCloseButton ? (
        <_Builtin.Link
          className="map-button-show-close-icon"
          button={false}
          aria-label="Close Interactive Map"
          block="inline"
          options={{
            href: "#",
          }}
          {...toggleMapButtonProps}
        >
          <_Builtin.HtmlEmbed
            className="icon-embed-xsmall---rotate-45"
            value="%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%2523333%22%20viewbox%3D%220%200%2029%2029%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M14.5%208.5c-.75%200-1.5.75-1.5%201.5v3h-3c-.75%200-1.5.75-1.5%201.5S9.25%2016%2010%2016h3v3c0%20.75.75%201.5%201.5%201.5S16%2019.75%2016%2019v-3h3c.75%200%201.5-.75%201.5-1.5S19.75%2013%2019%2013h-3v-3c0-.75-.75-1.5-1.5-1.5z%22%2F%3E%3C%2Fsvg%3E"
          />
        </_Builtin.Link>
      ) : null}
    </_Component>
  );
}
