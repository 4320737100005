"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function HeroSliderSlide({
  as: _Component = _Builtin.Block,
  heroSliderSlideImageImage = "https://uploads-ssl.webflow.com/656bce4e0dae8369c9788229/656beec750c7f84477e2c301_Rectangle%2093.png",
  topTextText = "1250 Lakeside",
  bottomTextText = "Sunnyvale ca",
  imagePriority = "auto",
}) {
  return (
    <_Component className="hero-slider_slide-wrapper" tag="div">
      <_Builtin.Block className="hero-slider_image-description" tag="div">
        <_Builtin.Block
          className="hero-slider_image-description-wrapper"
          tag="div"
        >
          <_Builtin.Block className="text-24" tag="div">
            {topTextText}
          </_Builtin.Block>
          <_Builtin.Block className="text-25" tag="div">
            {bottomTextText}
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block className="hero-slider_slide-image-wrapper" tag="div">
        <_Builtin.Image
          className="hero-slider_slide-image"
          loading="eager"
          width="auto"
          height="auto"
          fetchPriority={imagePriority}
          alt=""
          src={heroSliderSlideImageImage}
        />
      </_Builtin.Block>
    </_Component>
  );
}
