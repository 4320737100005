"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { AdvSearchAmenitiesCheckboxItem } from "./AdvSearchAmenitiesCheckboxItem";

export function AdvSearchAmenitiesDropdown2({
  as: _Component = _Builtin.Block,
  children,
  buttonText = "Amenities",
  showPopover = true,
  buttonProps = {},
  showButton = true,
  showInline = true,
}) {
  return (
    <_Component className="div-block-85" tag="div">
      <_Builtin.Block className=" adv-search-lable" tag="div">
        {"Amenities"}
      </_Builtin.Block>
      <_Builtin.Block className="search-thin-component-2-copy" tag="div">
        <_Builtin.Block className="amenities-container" tag="div">
          {showButton ? (
            <_Builtin.Link
              className="button-11"
              button={true}
              block=""
              options={{
                href: "#",
              }}
              {...buttonProps}
            >
              {buttonText}
            </_Builtin.Link>
          ) : null}
          {showInline ? (
            <_Builtin.Block className="amenities-inline-container" tag="div">
              <_Builtin.List
                className="amenities-list-inline"
                tag="ul"
                unstyled={false}
              >
                {children ?? (
                  <>
                    <AdvSearchAmenitiesCheckboxItem
                      text="Onsite Pet Friendly Dog Parks"
                      showDisabled={false}
                    />
                    <AdvSearchAmenitiesCheckboxItem showDisabled={false} />
                    <AdvSearchAmenitiesCheckboxItem />
                    <AdvSearchAmenitiesCheckboxItem />
                    <AdvSearchAmenitiesCheckboxItem />
                    <AdvSearchAmenitiesCheckboxItem />
                    <AdvSearchAmenitiesCheckboxItem />
                    <AdvSearchAmenitiesCheckboxItem />
                    <AdvSearchAmenitiesCheckboxItem />
                    <AdvSearchAmenitiesCheckboxItem />
                    <AdvSearchAmenitiesCheckboxItem />
                    <AdvSearchAmenitiesCheckboxItem />
                    <AdvSearchAmenitiesCheckboxItem />
                    <AdvSearchAmenitiesCheckboxItem />
                    <AdvSearchAmenitiesCheckboxItem />
                    <AdvSearchAmenitiesCheckboxItem />
                    <AdvSearchAmenitiesCheckboxItem />
                    <AdvSearchAmenitiesCheckboxItem />
                    <AdvSearchAmenitiesCheckboxItem />
                    <AdvSearchAmenitiesCheckboxItem />
                    <AdvSearchAmenitiesCheckboxItem />
                  </>
                )}
              </_Builtin.List>
            </_Builtin.Block>
          ) : null}
          {showPopover ? (
            <_Builtin.Block className="amenities-popover-container" tag="div">
              <_Builtin.List
                className="amenities-list"
                tag="ul"
                unstyled={false}
              >
                {children ?? (
                  <>
                    <AdvSearchAmenitiesCheckboxItem
                      text="Onsite Pet Friendly Dog Parks"
                      showDisabled={false}
                    />
                    <AdvSearchAmenitiesCheckboxItem showDisabled={false} />
                    <AdvSearchAmenitiesCheckboxItem />
                    <AdvSearchAmenitiesCheckboxItem />
                    <AdvSearchAmenitiesCheckboxItem />
                  </>
                )}
              </_Builtin.List>
            </_Builtin.Block>
          ) : null}
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}
