"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { RegionalPageRichText } from "./RegionalPageRichText";
import { RegionalPageApartmentCount } from "./RegionalPageApartmentCount";

export function ExploreDescription({
  as: _Component = _Builtin.Block,
  copyTopRichText = "",
  numberOfApartmentsText = "# Apartments in Market",
}) {
  return (
    <_Component
      className="explore_left"
      id="w-node-e29151de-eca2-8cbe-6ad2-dda610c0f532-10c0f532"
      tag="div"
    >
      <_Builtin.Block className="div-block-57" tag="div">
        <_Builtin.Block className="div-block-64" tag="div">
          <_Builtin.Block className="decoration-line is-small" tag="div" />
        </_Builtin.Block>
        <RegionalPageRichText copyTopRichText={copyTopRichText} />
        <RegionalPageApartmentCount
          numberOfApartmentsText={numberOfApartmentsText}
        />
      </_Builtin.Block>
    </_Component>
  );
}
