"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { SearchInputSearchButton } from "./SearchInputSearchButton";
import { SearchInputClearButton } from "./SearchInputClearButton";
import { SearchModalResultItem } from "./SearchModalResultItem";

export function SearchModal({
  as: _Component = _Builtin.Block,
  showPopover = true,
}) {
  return (
    <_Component className="frame-151" tag="div">
      <_Builtin.Block className="frame-136" tag="div">
        <_Builtin.Block className="search-large-component" tag="div">
          <_Builtin.FormWrapper className="form-block-copy">
            <_Builtin.FormForm
              className="form"
              name="email-form-2"
              data-name="Email Form 2"
              method="get"
              id="email-form-2"
            >
              <_Builtin.Block className="seach-frame-container-large" tag="div">
                <_Builtin.FormTextInput
                  className="text-field-large"
                  autoFocus={false}
                  maxLength={256}
                  name="search-input-2"
                  data-name="Search Input 2"
                  placeholder="Search by Location or Community Name"
                  type="text"
                  disabled={false}
                  required={true}
                  id="search-input-2"
                />
                <SearchInputSearchButton />
                <SearchInputClearButton
                  clearSearchProps={{}}
                  showClearButton={true}
                />
              </_Builtin.Block>
            </_Builtin.FormForm>
            <_Builtin.FormSuccessMessage />
            <_Builtin.FormErrorMessage />
          </_Builtin.FormWrapper>
        </_Builtin.Block>
      </_Builtin.Block>
      {showPopover ? (
        <_Builtin.Block className="search-results-large" tag="div">
          <_Builtin.Block className="search-results-container" tag="div">
            <_Builtin.Block className="frame-148" tag="div">
              <_Builtin.Block className="text-46" tag="div">
                {"OUR LOCATIONS"}
              </_Builtin.Block>
              <_Builtin.Block className="search---divider" tag="div" />
            </_Builtin.Block>
            <_Builtin.Block className="frame-147" tag="div">
              <SearchModalResultItem />
              <SearchModalResultItem />
              <SearchModalResultItem />
              <SearchModalResultItem />
            </_Builtin.Block>
            <_Builtin.Block className=" frame-148" tag="div">
              <_Builtin.Block className=" text-46" tag="div">
                {"OUR HOMES"}
              </_Builtin.Block>
              <_Builtin.Block className="search---divider" tag="div" />
            </_Builtin.Block>
            <_Builtin.Block className="frame-212" tag="div">
              <SearchModalResultItem text="apartment name" />
              <SearchModalResultItem text="apartment name" />
            </_Builtin.Block>
          </_Builtin.Block>
        </_Builtin.Block>
      ) : null}
    </_Component>
  );
}
