"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { NavSubMarketListLink } from "./NavSubMarketListLink";

export function NavMarketListRowContainer({
  as: _Component = _Builtin.Block,
  children,
  text = "market",
}) {
  return (
    <_Component className="nav-market-list-market-row-container" tag="div">
      <_Builtin.Block className="div-block-30" tag="div">
        <_Builtin.Block className="nav-market-list-heading" tag="div">
          {text}
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block className="nav-sub-market-list" tag="div">
        {children ?? (
          <>
            <NavSubMarketListLink text="Sub dsfgsdfgdsfg" showSpacer={true} />
            <NavSubMarketListLink text="Sub Market" />
            <NavSubMarketListLink text="Sub Market" />
            <NavSubMarketListLink text="Sub Market" />
            <NavSubMarketListLink text="Sub Market" />
            <NavSubMarketListLink text="Sub Market" />
            <NavSubMarketListLink text="Sub Market" showSpacer={false} />
          </>
        )}
      </_Builtin.Block>
    </_Component>
  );
}
