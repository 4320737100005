"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function MarketToggleCityLink({
  as: _Component = _Builtin.Link,
  text = "Text Link",

  link = {
    href: "#",
  },
}) {
  return (
    <_Component
      className="text-color-grey"
      id="w-node-_8e90f9c2-b8a6-ea23-2c24-33a3ef757187-ef757187"
      button={false}
      block=""
      options={link}
    >
      {text}
    </_Component>
  );
}
