"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function Footer({ as: _Component = _Builtin.Block }) {
  return (
    <_Component className="footer" tag="footer">
      <_Builtin.Block className="padding-global" tag="div">
        <_Builtin.Block className="container-large" tag="div">
          <_Builtin.Block
            className="padding-vertical padding-xxlarge"
            tag="div"
          >
            <_Builtin.Block className="margin-bottom margin-medium" tag="div">
              <_Builtin.NavbarBrand
                className="footer3_logo-link"
                aria-label="Navigate to the homepage of the website"
                options={{
                  href: "/",
                }}
              >
                <_Builtin.Block className="logo-container-footer" tag="div">
                  <_Builtin.Image
                    className="navbar2_logo"
                    loading="lazy"
                    width="Auto"
                    height="auto"
                    alt=""
                    src="https://uploads-ssl.webflow.com/656bce4e0dae8369c9788229/656ce0fa2da4744f69f8eadb_Vectors-Wrapper.svg"
                  />
                </_Builtin.Block>
              </_Builtin.NavbarBrand>
            </_Builtin.Block>
            <_Builtin.Grid className="footer3_top-wrapper" tag="div">
              <_Builtin.Block
                className="footer3_left-wrapper"
                id="w-node-eeca584d-267e-d72f-fabf-bedad8790353-d879034b"
                tag="div"
              >
                <_Builtin.Block className="footer3_details-wrapper" tag="div">
                  <_Builtin.Block tag="div">
                    <_Builtin.Block
                      className="margin-bottom margin-small"
                      tag="div"
                    >
                      <_Builtin.Block className="text-size-small" tag="div">
                        {"Sares Regis Group, Residential, Inc."}
                        <br />
                        {"License #01192016"}
                      </_Builtin.Block>
                    </_Builtin.Block>
                    <_Builtin.Block
                      className="footer_details-link-list"
                      tag="div"
                    >
                      <_Builtin.Link
                        className="footer_details-link"
                        button={false}
                        block=""
                        options={{
                          href: "/privacy-policy",
                        }}
                      >
                        {"Privacy Policy"}
                      </_Builtin.Link>
                      <_Builtin.Link
                        className="footer_details-link"
                        button={false}
                        block=""
                        options={{
                          href: "/website-disclaimer",
                        }}
                      >
                        {"Website Disclaimer"}
                      </_Builtin.Link>
                      <_Builtin.Link
                        className="footer_details-link"
                        button={false}
                        block=""
                        options={{
                          href: "/disclosures-and-licenses",
                        }}
                      >
                        {"Disclosures & Licenses"}
                      </_Builtin.Link>
                      <_Builtin.Link
                        className="footer_details-link"
                        button={false}
                        block=""
                        options={{
                          href: "https://privacyportal.onetrust.com/webform/c485392c-9ffa-408e-80f2-3b9bdc8de724/0b23fc21-9c92-4464-af60-fa0f6936192c",
                        }}
                      >
                        {"Do Not Sell My Personal Information"}
                      </_Builtin.Link>
                      <_Builtin.Link
                        className="footer_details-link"
                        button={false}
                        block=""
                        options={{
                          href: "/accessibility-statement",
                        }}
                      >
                        {"Accessibility Statement"}
                      </_Builtin.Link>
                      <_Builtin.Link
                        className="footer_details-link"
                        button={false}
                        block=""
                        options={{
                          href: "https://www.lineups.io",
                          target: "_blank",
                        }}
                      >
                        {"Made by Lineups"}
                      </_Builtin.Link>
                    </_Builtin.Block>
                  </_Builtin.Block>
                  <_Builtin.Block tag="div">
                    <_Builtin.Block
                      className="margin-bottom margin-small"
                      tag="div"
                    >
                      <_Builtin.Link
                        className="footer_details-link"
                        button={false}
                        block="inline"
                        options={{
                          href: "#",
                        }}
                      >
                        <_Builtin.Block className="text-size-small" tag="div">
                          {"Contact Us"}
                        </_Builtin.Block>
                      </_Builtin.Link>
                    </_Builtin.Block>
                    <_Builtin.Block
                      className="footer_details-link-list"
                      tag="div"
                    >
                      <_Builtin.Link
                        className="footer_details-link"
                        button={false}
                        block=""
                        options={{
                          href: "tel:949.756.5959",
                        }}
                      >
                        {"949.756.5959"}
                      </_Builtin.Link>
                      <_Builtin.Block className="div-block-31" tag="div">
                        <_Builtin.HtmlEmbed
                          className="icon-embed-custom"
                          value="%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20viewbox%3D%220%200%2024%2017%22%20fill%3D%22none%22%20preserveaspectratio%3D%22xMidYMid%20meet%22%20aria-hidden%3D%22true%22%20role%3D%22img%22%3E%0A%3Cg%20clip-path%3D%22url(%23clip0_214_3247)%22%3E%0A%3Cpath%20d%3D%22M11.911%200L0%205.90525V8.67917H1.33317V17.0001H22.3993V8.67917H24.0001V5.90525L11.911%200ZM19.8219%2014.494H19.8218H3.91091V6.88942L11.911%202.77382L19.8219%206.88942V14.494Z%22%20fill%3D%22currentColor%22%2F%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M15.5545%209.57312H8.17773V6.88953H15.5545V9.57312ZM15.5545%2013.4222H8.17773V10.7368H15.5545V13.4222Z%22%20fill%3D%22currentColor%22%2F%3E%0A%3C%2Fg%3E%0A%3Cdefs%3E%0A%3Cclippath%20id%3D%22clip0_214_3247%22%3E%0A%3Crect%20width%3D%2224%22%20height%3D%2217%22%20fill%3D%22currentColor%22%2F%3E%0A%3C%2Fclippath%3E%0A%3C%2Fdefs%3E%0A%3C%2Fsvg%3E"
                        />
                        <_Builtin.HtmlEmbed
                          className="icon-embed-custom-2"
                          value="%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20viewbox%3D%220%200%2016%2017%22%20fill%3D%22none%22%20preserveaspectratio%3D%22xMidYMid%20meet%22%20aria-hidden%3D%22true%22%20role%3D%22img%22%3E%0A%3Cg%20clip-path%3D%22url(%23clip0_214_3250)%22%3E%0A%3Cpath%20d%3D%22M9.93589%2011.9626C9.68221%2013.99%207.89168%2015.5684%205.72526%2015.5684C3.38479%2015.5684%201.48131%2013.7288%201.48131%2011.4669C1.48131%2010.1964%202.08309%209.05826%203.02372%208.30668V6.59058C1.22578%207.52468%200%209.36068%200%2011.4669C0%2014.5179%202.56822%2016.9999%205.72526%2016.9999C8.11943%2016.9999%2010.1747%2015.5719%2011.0284%2013.5498L9.93774%2011.9626H9.93589Z%22%20fill%3D%22currentColor%22%2F%3E%0A%3Cpath%20d%3D%22M15.2947%2012.9844L14.1133%2013.7718L11.0766%208.85789L5.24767%208.89011V7.72337H8.33435V6.47074H5.24767V3.57537C5.96795%203.31053%206.48271%202.63768%206.48271%201.84853C6.48271%200.826737%205.6254%200%204.56997%200C3.51453%200%202.65723%200.828526%202.65723%201.84853C2.65723%202.66095%203.19976%203.34989%203.95337%203.59684V10.1087L10.36%2010.1159L13.68%2015.4915L16.002%2014.0348L15.2965%2012.9844H15.2947Z%22%20fill%3D%22currentColor%22%2F%3E%0A%3C%2Fg%3E%0A%3Cdefs%3E%0A%3Cclippath%20id%3D%22clip0_214_3250%22%3E%0A%3Crect%20width%3D%2216%22%20height%3D%2217%22%20fill%3D%22currentColor%22%2F%3E%0A%3C%2Fclippath%3E%0A%3C%2Fdefs%3E%0A%3C%2Fsvg%3E"
                        />
                      </_Builtin.Block>
                      <_Builtin.Link
                        className="footer_details-link"
                        button={false}
                        block=""
                        options={{
                          href: "/",
                        }}
                      >
                        {"© 2023 Copyright SRG Residential"}
                      </_Builtin.Link>
                    </_Builtin.Block>
                  </_Builtin.Block>
                </_Builtin.Block>
              </_Builtin.Block>
              <_Builtin.Grid
                className="footer3_menu-wrapper"
                id="w-node-eeca584d-267e-d72f-fabf-bedad8790370-d879034b"
                tag="div"
              >
                <_Builtin.Block className="footer3_link-list" tag="div">
                  <_Builtin.Link
                    className="footer3_link"
                    button={false}
                    block=""
                    options={{
                      href: "#",
                    }}
                  >
                    {"Find Your Home"}
                  </_Builtin.Link>
                  <_Builtin.Link
                    className="footer3_link"
                    button={false}
                    block=""
                    options={{
                      href: "/residents",
                    }}
                  >
                    {"Residents"}
                  </_Builtin.Link>
                  <_Builtin.Link
                    className="footer3_link"
                    button={false}
                    block=""
                    options={{
                      href: "/applicants",
                    }}
                  >
                    {"Applicants"}
                  </_Builtin.Link>
                  <_Builtin.Link
                    className="footer3_link"
                    button={false}
                    block=""
                    options={{
                      href: "https://careers-sares-regis.icims.com/jobs/intro?hashed=-435627079&mobile=false&width=940&height=500&bga=true&needsRedirect=false&jan1offset=-360&jun1offset=-300",
                      target: "_blank",
                    }}
                  >
                    {"Careers"}
                  </_Builtin.Link>
                  <_Builtin.Link
                    className="footer3_link"
                    button={false}
                    block=""
                    options={{
                      href: "/contact-us",
                    }}
                  >
                    {"Contact"}
                  </_Builtin.Link>
                </_Builtin.Block>
              </_Builtin.Grid>
            </_Builtin.Grid>
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}
