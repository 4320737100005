"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function PropertyCardNoResults({
  as: _Component = _Builtin.Block,
  resetSearchRuntimeProps = {},
}) {
  return (
    <_Component className="card---no-results" tag="div">
      <_Builtin.Block className="no-results-copy" tag="div">
        <_Builtin.Block className="no-results-heading" tag="div">
          <_Builtin.Heading className="text-50" tag="h3">
            {"No homes Found "}
            <br />
            {"In this area"}
          </_Builtin.Heading>
        </_Builtin.Block>
        <_Builtin.Block className="frame-215-copy" tag="div">
          <_Builtin.Link
            className="frame-216"
            button={false}
            block=""
            options={{
              href: "#",
            }}
            {...resetSearchRuntimeProps}
          >
            <_Builtin.Paragraph className="text-53">
              {"Expand Search Results"}
            </_Builtin.Paragraph>
          </_Builtin.Link>
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}
