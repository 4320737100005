"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function RegionalPageRichText({
  as: _Component = _Builtin.Block,
  copyTopRichText = "",
}) {
  return (
    <_Component tag="div">
      <_Builtin.Block className="regional-page-rt-container" tag="div">
        <_Builtin.RichText className="text-rich-text" tag="div">
          {copyTopRichText}
        </_Builtin.RichText>
      </_Builtin.Block>
    </_Component>
  );
}
