"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { AdvSearchComponentsWrapper } from "./AdvSearchComponentsWrapper";

export function AdvSearchRow({
  as: _Component = _Builtin.Block,
  textHeading = "Market Name Here",
}) {
  return (
    <_Component className="adv-search-row" tag="div">
      <_Builtin.FormWrapper className="adv-search-form-block-container">
        <_Builtin.FormForm
          className="form-2"
          name="email-form-3"
          data-name="Email Form 3"
          method="get"
          id="email-form-3"
        >
          <AdvSearchComponentsWrapper showDesktopFilterButton={false} />
        </_Builtin.FormForm>
        <_Builtin.FormSuccessMessage />
        <_Builtin.FormErrorMessage />
      </_Builtin.FormWrapper>
    </_Component>
  );
}
