"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function CommunityListResult({
  as: _Component = _Builtin.Link,
  text = "apartment name",
  runtimeProps = {},
}) {
  return (
    <_Component
      className="apartment-list-result-link-com-copy"
      button={false}
      aria-label="Open Resident Login Link"
      block="inline"
      options={{
        href: "#",
      }}
      {...runtimeProps}
    >
      <_Builtin.Block className="apartment-list-result-container" tag="div">
        <_Builtin.Block className="apartment-list-text-container" tag="div">
          <_Builtin.Block className="text-block-17" tag="div">
            {text}
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}
