"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function AdvSearchSearchInputDropDownItem({
  as: _Component = _Builtin.Block,
  showDisabled = true,
  showEnabled = true,
  text = "Market Name",
  buttonProps = {},
}) {
  return (
    <_Component className="adv-search---result-item-container" tag="div">
      {showDisabled ? (
        <_Builtin.Link
          className="search-results-link-block"
          button={false}
          block="inline"
          options={{
            href: "#",
          }}
          {...buttonProps}
        >
          <_Builtin.Block
            className="search-results---submarket-container"
            tag="div"
          >
            <_Builtin.Block className="results-text-blcok" tag="div">
              {text}
            </_Builtin.Block>
          </_Builtin.Block>
        </_Builtin.Link>
      ) : null}
      {showEnabled ? (
        <_Builtin.Block className="search-results-link-block enabled" tag="div">
          <_Builtin.Block
            className="search-results---submarket-container"
            tag="div"
          >
            <_Builtin.Block className="results-text-blcok" tag="div">
              {text}
            </_Builtin.Block>
          </_Builtin.Block>
        </_Builtin.Block>
      ) : null}
    </_Component>
  );
}
