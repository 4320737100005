"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { MarketToggleCityLink } from "./MarketToggleCityLink";

export function MarketToggleCity({
  as: _Component = _Builtin.Block,
  children,
  city = "City",
}) {
  return (
    <_Component className="nav-market-toggle-sub-section-container" tag="div">
      <_Builtin.Block className="padding-global" tag="div">
        <_Builtin.Block className="container-large" tag="div">
          <_Builtin.Block
            className="nav-market-toggle-community-list-container"
            tag="div"
          >
            <_Builtin.Block
              className="nav-market-toggle-section-heading-copy"
              tag="div"
            >
              <_Builtin.Block className="nav-market-list-heading" tag="div">
                {city}
              </_Builtin.Block>
            </_Builtin.Block>
            <_Builtin.Block className="nav-market-city-apt-list-slot" tag="div">
              {children ?? (
                <>
                  <MarketToggleCityLink text="Alison Creek " />
                  <MarketToggleCityLink />
                  <MarketToggleCityLink />
                  <MarketToggleCityLink />
                  <MarketToggleCityLink />
                  <MarketToggleCityLink />
                  <MarketToggleCityLink />
                  <MarketToggleCityLink />
                  <MarketToggleCityLink />
                  <MarketToggleCityLink />
                  <MarketToggleCityLink />
                </>
              )}
            </_Builtin.Block>
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}
