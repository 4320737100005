"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function CardIconFitness({ as: _Component = _Builtin.Block }) {
  return (
    <_Component className="amenity---icon" tag="div" title="EV Charging">
      <_Builtin.HtmlEmbed
        className="html-embed-2"
        value="%3Csvg%20viewBox%3D%220%200%2013%209%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20aria-labelledby%3D%22title%22%3E%0A%3Ctitle%20id%3D%22title%22%20lang%3D%22en%22%3EThis%20community%20has%20a%20fitness%20center.%3C%2Ftitle%3E%0A%3Cpath%20d%3D%22M2.99055%200.453125V8.94445H4.81012V5.30531H8.44925V8.94445H10.2688V0.453125H8.44925V4.09226H4.81012V0.453125H2.99055ZM1.17098%206.51836C1.17098%206.67922%201.23488%206.83349%201.34862%206.94723C1.46237%207.06098%201.61664%207.12488%201.7775%207.12488H2.38402V2.27269H1.7775C1.61664%202.27269%201.46237%202.3366%201.34862%202.45034C1.23488%202.56409%201.17098%202.71836%201.17098%202.87922V4.09226H0.564453V5.30531H1.17098V6.51836ZM12.0884%202.87922C12.0884%202.71836%2012.0245%202.56409%2011.9107%202.45034C11.797%202.3366%2011.6427%202.27269%2011.4819%202.27269H10.8753V7.12488H11.4819C11.6427%207.12488%2011.797%207.06098%2011.9107%206.94723C12.0245%206.83349%2012.0884%206.67922%2012.0884%206.51836V5.30531H12.6949V4.09226H12.0884V2.87922Z%22%20fill%3D%22currentColor%22%2F%3E%0A%3C%2Fsvg%3E"
      />
    </_Component>
  );
}
