"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { CommunityListResult } from "./CommunityListResult";

export function CommunityListPopover({
  as: _Component = _Builtin.Block,
  children,
  toggleModalRuntimeProps = {},
  modalHeadingText = "Login Links",
}) {
  return (
    <_Component className="community-list-popover" tag="div">
      <_Builtin.Link
        className="community-list-select"
        button={false}
        block="inline"
        options={{
          href: "#",
        }}
        {...toggleModalRuntimeProps}
      >
        <_Builtin.Block className="community-list-text-heading" tag="div">
          {"Select Your Community"}
        </_Builtin.Block>
        <_Builtin.HtmlEmbed
          className="html-embed-7"
          value="%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%0A%20%20%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M256%20406.6l11.3-11.3%20192-192L470.6%20192%20448%20169.4l-11.3%2011.3L256%20361.4%2075.3%20180.7%2064%20169.4%2041.4%20192l11.3%2011.3%20192%20192L256%20406.6z%22%2F%3E%3C%2Fsvg%3E"
        />
      </_Builtin.Link>
      <_Builtin.Block
        className="hide-opac-0---community-list-popover-modal-copy"
        tag="div"
      >
        <_Builtin.Block className="div-block-109" tag="div">
          <_Builtin.Block className="div-block-110" tag="div">
            <_Builtin.Block className="div-block-111" tag="div">
              <_Builtin.Block
                className="community-list-modal-heading-line-2"
                tag="div"
              >
                {modalHeadingText}
              </_Builtin.Block>
            </_Builtin.Block>
            <_Builtin.Link
              className="link-block-6"
              button={false}
              block="inline"
              options={{
                href: "#",
              }}
              {...toggleModalRuntimeProps}
            >
              <_Builtin.Block className="text-block-16" tag="div">
                {"Close"}
              </_Builtin.Block>
            </_Builtin.Link>
          </_Builtin.Block>
          <_Builtin.Block className="community-list-popover-slot" tag="div">
            {children ?? (
              <>
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
                <CommunityListResult text="apartment name" />
              </>
            )}
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block className="apartment-list-background" tag="div" />
      </_Builtin.Block>
    </_Component>
  );
}
