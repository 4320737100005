"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function AdvSearchMoveInDropdown({
  as: _Component = _Builtin.Block,
  runtimeProps = {},
}) {
  return (
    <_Component className="move-in-group" tag="div">
      <_Builtin.Block className=" adv-search-lable" tag="div">
        {"Move-in"}
      </_Builtin.Block>
      <_Builtin.Block className="input-dropdown" tag="div">
        <_Builtin.Block className="adv-search-drop-down-wrapper" tag="div">
          <_Builtin.FormSelect
            className="adv-search-dropdown"
            name="Move-In-Date"
            data-name="Move-In-Date"
            required={false}
            multiple={false}
            id="Move-In-Date"
            options={[
              {
                t: "Next 7 Days",
                v: "<7",
              },
              {
                t: "Next 15 Days",
                v: "<16",
              },
              {
                t: "Next 30 Days",
                v: "<31",
              },
              {
                t: "After 30 Days",
                v: ">30",
              },
            ]}
            {...runtimeProps}
          />
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}
