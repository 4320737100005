"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function SearchInputClearButton({
  as: _Component = _Builtin.Link,
  clearSearchProps = {},
  showClearButton = true,
}) {
  return showClearButton ? (
    <_Component
      className="search_clear-button"
      button={false}
      block="inline"
      options={{
        href: "#",
      }}
      {...clearSearchProps}
    >
      <_Builtin.Block className="clear-button_wrapper" tag="div">
        <_Builtin.HtmlEmbed
          className="icon-embed-xxsmall"
          value="%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20aria-hidden%3D%22true%22%20role%3D%22img%22%20class%3D%22iconify%20iconify--fe%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20preserveaspectratio%3D%22xMidYMid%20meet%22%20viewbox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22currentColor%22%20fill-rule%3D%22evenodd%22%20d%3D%22M10.657%2012.071L5%206.414L6.414%205l5.657%205.657L17.728%205l1.414%201.414l-5.657%205.657l5.657%205.657l-1.414%201.414l-5.657-5.657l-5.657%205.657L5%2017.728z%22%2F%3E%3C%2Fsvg%3E"
        />
      </_Builtin.Block>
    </_Component>
  ) : null;
}
