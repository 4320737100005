"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { AdvSearchBedroomGroupButtonV2 } from "./AdvSearchBedroomGroupButtonV2";

export function AdvSearchBedroomGroup({
  as: _Component = _Builtin.Block,
  children,
}) {
  return (
    <_Component className=" bedroom-group" tag="div">
      <_Builtin.Block className=" adv-search-lable" tag="div">
        {"Bedrooms"}
      </_Builtin.Block>
      <_Builtin.Block className="bedroom-option" tag="div">
        {children ?? (
          <>
            <AdvSearchBedroomGroupButtonV2
              showInActive={true}
              showActive={false}
              text="studio"
            />
            <AdvSearchBedroomGroupButtonV2
              showInActive={false}
              showActive={true}
              text="1"
            />
            <AdvSearchBedroomGroupButtonV2
              showInActive={true}
              showActive={false}
              text="1"
            />
            <AdvSearchBedroomGroupButtonV2
              showInActive={true}
              showActive={false}
              text="1"
            />
            <AdvSearchBedroomGroupButtonV2
              showInActive={true}
              showActive={false}
              text="2"
            />
          </>
        )}
      </_Builtin.Block>
    </_Component>
  );
}
