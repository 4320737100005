"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function AdvSearchBedroomGroupButtonV2({
  as: _Component = _Builtin.Block,
  showActive = true,
  showInActive = true,
  text = "0",
  buttonProps = {},
}) {
  return (
    <_Component className="div-block-69" tag="div">
      {showInActive ? (
        <_Builtin.Link
          className="bedroom-button"
          button={true}
          block=""
          options={{
            href: "#",
          }}
          {...buttonProps}
        >
          {text}
        </_Builtin.Link>
      ) : null}
      {showActive ? (
        <_Builtin.Link
          className="bedroom-button active"
          button={true}
          block=""
          options={{
            href: "#",
          }}
          {...buttonProps}
        >
          {text}
        </_Builtin.Link>
      ) : null}
    </_Component>
  );
}
