"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function BelowHeroRightImage({
  as: _Component = _Builtin.Image,
  image = "https://uploads-ssl.webflow.com/656bce4e0dae8369c9788229/65b17e747b3990d7dab72e52_pet.jpg",
  show,
}) {
  return show ? (
    <_Component
      className="image-16"
      loading="lazy"
      width="auto"
      height="auto"
      fetchPriority="high"
      alt=""
      src={image}
    />
  ) : null;
}
