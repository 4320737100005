"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function CardIconPrivateGarage({ as: _Component = _Builtin.Block }) {
  return (
    <_Component className="amenity---icon" tag="div">
      <_Builtin.HtmlEmbed
        className="html-embed-2"
        value="%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20640%20512%22%20aria-labelledby%3D%22title%22%3E%3E%0A%3Ctitle%20id%3D%22title%22%20lang%3D%22en%22%3EThis%20community%20has%20private%20garages.%3C%2Ftitle%3E%0A%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M0%20171.3V488c0%2013.3%2010.7%2024%2024%2024H72c13.3%200%2024-10.7%2024-24V224c0-17.7%2014.3-32%2032-32H512c17.7%200%2032%2014.3%2032%2032V488c0%2013.3%2010.7%2024%2024%2024h48c13.3%200%2024-10.7%2024-24V171.3c0-26.2-15.9-49.7-40.2-59.4L331.9%204.8c-7.6-3.1-16.1-3.1-23.8%200L40.2%20111.9C15.9%20121.6%200%20145.2%200%20171.3zM128%20224v96H512V224H128zm0%20128V512H512V352H128zm128%2080c0-8.8%207.2-16%2016-16h96c8.8%200%2016%207.2%2016%2016s-7.2%2016-16%2016H272c-8.8%200-16-7.2-16-16z%22%2F%3E%3C%2Fsvg%3E"
      />
    </_Component>
  );
}
