"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function ContactFormContactUsPageMessages({
  as: _Component = _Builtin.Block,
  showSuccess = true,
  showError = true,
}) {
  return (
    <_Component className="success-message white" tag="div">
      {showSuccess ? (
        <_Builtin.Block className="contact-form-success-white" tag="div">
          <_Builtin.Paragraph className="paragraph">
            {"Thank you for contacting us. "}
          </_Builtin.Paragraph>
        </_Builtin.Block>
      ) : null}
      {showError ? (
        <_Builtin.Block className="contact-form-error" tag="div">
          <_Builtin.Paragraph className="paragraph-2">
            {"Humm. there was an error."}
          </_Builtin.Paragraph>
        </_Builtin.Block>
      ) : null}
    </_Component>
  );
}
