"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function SearchInputPopoverHeading({
  as: _Component = _Builtin.Block,
  text = "OUR LOCATIONS",
}) {
  return (
    <_Component className="search-popover-heading" tag="div">
      <_Builtin.Block className="text-46" tag="div">
        {text}
      </_Builtin.Block>
      <_Builtin.Block className="search---divider" tag="div" />
    </_Component>
  );
}
