"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function ContactFormHomePageMessages({
  as: _Component = _Builtin.Block,
  showSuccess = true,
  showError = true,
}) {
  return (
    <_Component className="contact-form---messages" tag="div">
      {showSuccess ? (
        <_Builtin.Block className="success-message black" tag="div">
          <_Builtin.Paragraph>
            {
              "Success - Your message has been sent. Someone will be in contact with you soon."
            }
          </_Builtin.Paragraph>
        </_Builtin.Block>
      ) : null}
      {showError ? (
        <_Builtin.Block className="error-message black" tag="div">
          <_Builtin.Paragraph>
            {"Hummm ... Something went wrong. Can you try again. "}
          </_Builtin.Paragraph>
        </_Builtin.Block>
      ) : null}
    </_Component>
  );
}
