"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function NavSubMarketListLink({
  as: _Component = _Builtin.Block,
  text = "market",
  link,
  showSpacer,
}) {
  return (
    <_Component className="nav-sub-market-list-link-container-copy" tag="div">
      <_Builtin.Link
        className="nav-sub-market-list-link"
        button={false}
        block=""
        options={link}
      >
        {text}
      </_Builtin.Link>
      {showSpacer ? (
        <_Builtin.Block className="nav-sub-market-list-spacer" tag="div" />
      ) : null}
    </_Component>
  );
}
