"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function ButtonPrimaryBlack({
  as: _Component = _Builtin.Link,
  text = "Explore our communities",
  link,
}) {
  return (
    <_Component
      className="button-black-primary-grow"
      button={true}
      block=""
      options={link}
    >
      {text}
    </_Component>
  );
}
