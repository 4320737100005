"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function RegionalPageBottomLink({
  as: _Component = _Builtin.Link,
  textBlockText = "Luxury Apartments",

  amenityLink = {
    href: "#",
  },
}) {
  return (
    <_Component
      className="explore_amenities-link"
      button={false}
      block="inline"
      options={amenityLink}
    >
      <_Builtin.Block tag="div">{textBlockText}</_Builtin.Block>
      <_Builtin.HtmlEmbed
        className="icon-embed-xxsmall"
        value="%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20aria-hidden%3D%22true%22%20role%3D%22img%22%20class%3D%22iconify%20iconify--ic%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20preserveaspectratio%3D%22xMidYMid%20meet%22%20viewbox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M16.01%2011H4v2h12.01v3L20%2012l-3.99-4z%22%2F%3E%3C%2Fsvg%3E"
      />
    </_Component>
  );
}
