"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function AdvSearchButton({
  as: _Component = _Builtin.Block,
  text = "1",
}) {
  return (
    <_Component className=" adv-search-button-container" tag="div">
      <_Builtin.Link
        className="button-3"
        button={true}
        block=""
        options={{
          href: "#",
        }}
      >
        {text}
      </_Builtin.Link>
    </_Component>
  );
}
