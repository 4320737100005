"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function ContactFormHomePage({
  as: _Component = _Builtin.FormWrapper,
  formRuntimeProps = {},
  communitySelectRuntimeProp = {},
  formEventRuntimeProps = {},
}) {
  return (
    <_Component className="contact_form_component" {...formEventRuntimeProps}>
      <_Builtin.FormForm
        className="contact_form"
        name="wf-form-SRGLiving---Contact-Form"
        data-name="SRGLiving - Contact Form"
        method="get"
        id="wf-form-SRGLiving---Contact-Form"
        {...formRuntimeProps}
      >
        <_Builtin.Block className="div-block-36" tag="div">
          <_Builtin.Block className="form_field-wrapper" tag="div">
            <_Builtin.FormTextInput
              className="form_input"
              name="Name"
              maxLength={256}
              data-name="Name"
              placeholder="Name"
              disabled={false}
              type="text"
              required={true}
              autoFocus={false}
              aria-label="What is your first and last name?"
              id="Name-4"
            />
          </_Builtin.Block>
          <_Builtin.Block className="form_field-wrapper" tag="div">
            <_Builtin.FormTextInput
              className="form_input"
              name="Email"
              maxLength={256}
              data-name="Email"
              placeholder="Email"
              disabled={false}
              type="email"
              required={true}
              autoFocus={false}
              id="Email"
            />
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block className="form_field-wrapper" tag="div">
          <_Builtin.FormSelect
            className="form_input"
            name="Feedback"
            data-name="Feedback"
            required={true}
            multiple={false}
            aria-label="What kind of question do you have?"
            id="Feedback"
            options={[
              {
                t: "I have a question/comment about a community",
                v: "I have a question/comment about a community",
              },
              {
                t: "I have a question/comment for Sares-Regis",
                v: "I have a question/comment for Sares-Regis",
              },
              {
                t: "I'm a resident with a question",
                v: "I'm a resident with a question",
              },
            ]}
          />
        </_Builtin.Block>
        <_Builtin.Block className="form_field-wrapper" tag="div">
          <_Builtin.FormSelect
            className="form_input"
            name="Community"
            data-name="Community"
            required={false}
            multiple={false}
            aria-label="What community is this related to?"
            id="Community"
            options={[
              {
                t: "Community",
                v: "",
              },
              {
                t: "First choice",
                v: "First",
              },
              {
                t: "Second choice",
                v: "Second",
              },
              {
                t: "Third choice",
                v: "Third",
              },
            ]}
            {...communitySelectRuntimeProp}
          />
        </_Builtin.Block>
        <_Builtin.Block className="form_field-wrapper" tag="div">
          <_Builtin.FormTextarea
            className="form_input is-text-area"
            name="Message"
            maxLength={5000}
            data-name="Message"
            placeholder="Message"
            required={true}
            autoFocus={false}
            id="Message"
          />
        </_Builtin.Block>
        <_Builtin.Block
          className="margin-top margin-small"
          id="w-node-_72ea2081-fc0a-f3bd-eb7f-e4395dc407aa-5dc4079d"
          tag="div"
        >
          <_Builtin.FormButton
            className="button is-secondary"
            id="w-node-_72ea2081-fc0a-f3bd-eb7f-e4395dc407ab-5dc4079d"
            type="submit"
            value="Submit"
            data-wait="Please wait..."
          />
        </_Builtin.Block>
        <_Builtin.HtmlEmbed
          id="w-node-_49f71086-cee0-59cd-2ef5-598c1d13cc01-5dc4079d"
          value="%3Cinput%20type%3D%22hidden%22%20name%3D%22form-name%22%20value%3D%22contact%22%3E%0A%3Cinput%20type%3D%22hidden%22%20name%3D%22bot-field%22%20value%3D%22%22%3E"
        />
      </_Builtin.FormForm>
      <_Builtin.FormSuccessMessage className="success-message hide" />
      <_Builtin.FormErrorMessage className="error-message" />
    </_Component>
  );
}
