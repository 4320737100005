"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function ButtonPrimaryWhite({
  as: _Component = _Builtin.Link,
  text = "search",

  link = {
    href: "#",
  },

  showButton = true,
}) {
  return showButton ? (
    <_Component
      className="button-black-white-shrink"
      button={true}
      block=""
      options={link}
    >
      {text}
    </_Component>
  ) : null;
}
