"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function SearchInputNoResults({ as: _Component = _Builtin.Block }) {
  return (
    <_Component className="search-popover-heading-no-results" tag="div">
      <_Builtin.Block className="no-results-text" tag="div">
        {"No Results Found"}
        <br />
        {"Please Change Your search"}
      </_Builtin.Block>
    </_Component>
  );
}
