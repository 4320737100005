"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function ApartmentSearchPopoverItem({
  as: _Component = _Builtin.Block,
  showDisabled,
  showEnabled = false,
  text = "This is the default text value",
  buttonProps,
  children,
}) {
  return (
    <_Component className="div-block-77" tag="div">
      {showDisabled ? (
        <_Builtin.Link
          className="search-results-link-block"
          button={false}
          block="inline"
          options={{
            href: "#",
          }}
          {...buttonProps}
        >
          <_Builtin.Block
            className="search-results---submarket-container"
            tag="div"
          >
            {children ?? (
              <_Builtin.Block className="results-text-blcok large" tag="div">
                {text}
              </_Builtin.Block>
            )}
          </_Builtin.Block>
        </_Builtin.Link>
      ) : null}
      {showEnabled ? (
        <_Builtin.Block className="search-results-link-block enabled" tag="div">
          <_Builtin.Block
            className="search-results---submarket-container"
            tag="div"
          >
            {children ?? (
              <_Builtin.Block className="results-text-blcok large" tag="div">
                {text}
              </_Builtin.Block>
            )}
          </_Builtin.Block>
        </_Builtin.Block>
      ) : null}
    </_Component>
  );
}
