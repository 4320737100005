"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { RegionalPageBottomLink } from "./RegionalPageBottomLink";

export function RegionalPageBottomLinks({
  as: _Component = _Builtin.Block,
  title = "Amenities",
  children,
}) {
  return (
    <_Component className="section_amenities" tag="div">
      <_Builtin.Block className="margin-bottom margin-medium" tag="div">
        <_Builtin.Block className="heading-style-h6 text-color-blue" tag="div">
          {title}
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block className="links-slot" tag="div">
        {children ?? (
          <>
            <RegionalPageBottomLink />
            <RegionalPageBottomLink />
            <RegionalPageBottomLink />
            <RegionalPageBottomLink />
            <RegionalPageBottomLink />
            <RegionalPageBottomLink />
            <RegionalPageBottomLink />
            <RegionalPageBottomLink />
            <RegionalPageBottomLink />
            <RegionalPageBottomLink />
            <RegionalPageBottomLink />
            <RegionalPageBottomLink />
          </>
        )}
      </_Builtin.Block>
    </_Component>
  );
}
