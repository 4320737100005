"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function CardIconPet({ as: _Component = _Builtin.Block }) {
  return (
    <_Component className="amenity---icon" tag="div" title="EV Charging">
      <_Builtin.HtmlEmbed
        className="html-embed-2"
        value="%3Csvg%20viewBox%3D%220%200%2011%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20aria-labelledby%3D%22title%22%3E%0A%3Ctitle%20id%3D%22title%22%20lang%3D%22en%22%3EThis%20community%20is%20pet%20friendly.%3C%2Ftitle%3E%0A%3Cpath%20d%3D%22M3.52425%200.624848C4.16342%200.532764%204.8405%201.23151%205.0355%202.19568C5.2305%203.15443%204.87842%204.01026%204.23925%204.10776C3.6055%204.20526%202.923%203.50651%202.72258%202.54235C2.52217%201.5836%202.88508%200.727764%203.52425%200.624848ZM7.39717%200.624848C8.04175%200.727764%208.39925%201.5836%208.20967%202.54235C8.00383%203.50651%207.32675%204.20526%206.68758%204.10776C6.043%204.01026%205.69092%203.15443%205.89133%202.19568C6.08633%201.23151%206.76342%200.532764%207.39717%200.624848ZM0.626333%203.11651C1.24383%202.8511%202.08342%203.33318%202.52217%204.17276C2.93383%205.0286%202.793%205.92776%202.18092%206.19318C1.56883%206.4586%200.734666%205.98193%200.306749%205.13151C-0.121167%204.2811%200.0304994%203.37651%200.626333%203.11651ZM10.3763%203.11651C10.9722%203.37651%2011.1238%204.2811%2010.6959%205.13151C10.268%205.98193%209.43383%206.4586%208.82175%206.19318C8.20967%205.92776%208.06883%205.0286%208.4805%204.17276C8.91925%203.33318%209.75883%202.8511%2010.3763%203.11651ZM9.47175%208.95568C9.49342%209.46485%209.10342%2010.0282%208.63758%2010.2394C7.668%2010.6836%206.51967%209.76276%205.44175%209.76276C4.36383%209.76276%203.20467%2010.7215%202.25133%2010.2394C1.70967%209.97401%201.33592%209.26985%201.40633%208.68485C1.50383%207.87776%202.47342%207.44443%203.04758%206.85401C3.81133%206.09026%204.353%204.65485%205.44175%204.65485C6.52508%204.65485%207.09925%206.0686%207.8305%206.85401C8.43175%207.51485%209.43383%208.07276%209.47175%208.95568Z%22%20fill%3D%22currentColor%22%2F%3E%0A%3C%2Fsvg%3E"
      />
    </_Component>
  );
}
