"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function RegionalPageApartmentCount({
  as: _Component = _Builtin.Block,
  numberOfApartmentsText = "# Apartments in Market",
}) {
  return (
    <_Component tag="div">
      <_Builtin.Block
        className="regional-page-of-apartments-container"
        tag="div"
      >
        <_Builtin.Block className="heading-style-h6 text-color-blue" tag="div">
          {numberOfApartmentsText}
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}
