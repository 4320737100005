"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { RegionalPageBottomLink } from "./RegionalPageBottomLink";

export function Amenities({
  as: _Component = _Builtin.Block,
  headingText = "Amenities",
}) {
  return (
    <_Component className="section_amenities" tag="div">
      <_Builtin.Block tag="div">
        <_Builtin.Block className="margin-bottom margin-medium" tag="div">
          <_Builtin.Block
            className="heading-style-h6 text-color-blue"
            tag="div"
          >
            {headingText}
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block className="div-block-58" tag="div">
          <RegionalPageBottomLink />
          <RegionalPageBottomLink />
          <RegionalPageBottomLink />
          <RegionalPageBottomLink />
          <RegionalPageBottomLink />
          <RegionalPageBottomLink />
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}
