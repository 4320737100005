"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function MainNav({ as: _Component = _Builtin.Block }) {
  return (
    <_Component className="nav" tag="div">
      <_Builtin.Block className="nav-left" tag="div">
        <_Builtin.Image
          className="vectors-wrapper-7"
          loading="lazy"
          width="201.72203063964844"
          height="34.99755859375"
          src="https://uploads-ssl.webflow.com/656bce4e0dae8369c9788229/656ce0fa2da4744f69f8eadb_Vectors-Wrapper.svg"
        />
      </_Builtin.Block>
      <_Builtin.Block className="nav-center" tag="div">
        <_Builtin.Block className="residents" tag="div">
          <_Builtin.Link
            className="nav-link"
            button={false}
            block=""
            options={{
              href: "#",
            }}
          >
            {"Residents"}
          </_Builtin.Link>
        </_Builtin.Block>
        <_Builtin.Block className=" residents" tag="div">
          <_Builtin.Link
            className="nav-link"
            button={false}
            block=""
            options={{
              href: "#",
            }}
          >
            {"Applicants"}
          </_Builtin.Link>
        </_Builtin.Block>
        <_Builtin.Block className=" residents" tag="div">
          <_Builtin.Link
            className="nav-link"
            button={false}
            block=""
            options={{
              href: "#",
            }}
          >
            {"Careers"}
          </_Builtin.Link>
        </_Builtin.Block>
        <_Builtin.Block className=" residents" tag="div">
          <_Builtin.Link
            className="nav-link"
            button={false}
            block=""
            options={{
              href: "#",
            }}
          >
            {"Contact"}
          </_Builtin.Link>
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block className="nav-right" tag="div">
        <_Builtin.Link
          className="button-navigation"
          button={true}
          block=""
          options={{
            href: "#",
          }}
        >
          {"Find Your Home"}
        </_Builtin.Link>
      </_Builtin.Block>
    </_Component>
  );
}
