"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function RedoSearchButton({
  as: _Component = _Builtin.Block,
  showShowDisabled = true,
  showShowEnabled = true,
  buttonProps,
}) {
  return (
    <_Component className="redo-search-component" tag="div">
      {showShowDisabled ? (
        <_Builtin.Link
          className="redo-search disabled"
          button={true}
          block=""
          options={{
            href: "#",
          }}
          {...buttonProps}
        >
          {"Redo Search"}
        </_Builtin.Link>
      ) : null}
      {showShowEnabled ? (
        <_Builtin.Link
          className="redo-search"
          button={true}
          block=""
          options={{
            href: "#",
          }}
          {...buttonProps}
        >
          {"Redo Search"}
        </_Builtin.Link>
      ) : null}
    </_Component>
  );
}
