"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { ExploreCityLink } from "./ExploreCityLink";

export function ExploreSubmarkets({
  as: _Component = _Builtin.Block,
  children,
  show,
}) {
  return show ? (
    <_Component
      className="explore_right"
      id="w-node-b35f597b-e6f8-10b1-5591-783698741553-98741553"
      tag="div"
    >
      <_Builtin.Block className="margin-bottom margin-small" tag="div">
        <_Builtin.Block className="text-block-6" tag="div">
          {"Surrounding neighborhoods for you to explore:"}
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block className="margin-bottom margin-small" tag="div">
        <_Builtin.Block className="decoration-line is-grey" tag="div" />
      </_Builtin.Block>
      <_Builtin.Block className="explore_city-wrapper" tag="div">
        <_Builtin.Block className="explore_city-list" tag="div">
          {children ?? (
            <>
              <ExploreCityLink cityNameText="Scottsdale" />
              <ExploreCityLink cityNameText="Tempe" />
              <ExploreCityLink cityNameText="Chandler" />
              <ExploreCityLink cityNameText="North" />
            </>
          )}
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  ) : null;
}
