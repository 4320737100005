"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function SearchPageHero({
  as: _Component = _Builtin.Block,
  image = "https://uploads-ssl.webflow.com/656bce4e0dae8369c9788229/657253169f6915619c8b668e_Rectangle-86.png",
  children,
  text = "Homes Near",
  showSearchHero = false,
}) {
  return showSearchHero ? (
    <_Component className="search-page-hero-container" tag="header">
      <_Builtin.Block className="padding-global-v2" tag="div">
        <_Builtin.Block className="container-small" tag="div">
          <_Builtin.Block className="padding-section-medium" tag="div">
            <_Builtin.Block className="text-align-left" tag="div">
              <_Builtin.Heading className="text-color-white small-h1" tag="h1">
                {"Our Communities"}
              </_Builtin.Heading>
            </_Builtin.Block>
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block className="header69_background-image-wrapper" tag="div">
        <_Builtin.Block className="image-overlay-layer" tag="div" />
        <_Builtin.Image
          className="header69_background-image-copy"
          loading="eager"
          width="auto"
          height="auto"
          fetchPriority="high"
          alt=""
          src={image}
        />
      </_Builtin.Block>
    </_Component>
  ) : null;
}
