"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function AdvSearchSearchInputViewAllLocations({
  as: _Component = _Builtin.Block,
  buttonProps = {},
}) {
  return (
    <_Component className="adv-search---result-item-container-copy" tag="div">
      <_Builtin.Link
        className="link-2"
        button={false}
        block=""
        options={{
          href: "#",
        }}
        {...buttonProps}
      >
        {"Show All Locations"}
      </_Builtin.Link>
    </_Component>
  );
}
