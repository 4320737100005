"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { SearchInputSearchButton } from "./SearchInputSearchButton";
import { SearchInputClearButton } from "./SearchInputClearButton";
import { AdvSearchSearchInputDropDownItem } from "./AdvSearchSearchInputDropDownItem";
import { AdvSearchNoResults } from "./AdvSearchNoResults";
import { AdvSearchSearchInputViewAllLocations } from "./AdvSearchSearchInputViewAllLocations";

export function AdvSearchSearchInput({
  as: _Component = _Builtin.Block,
  showList = true,
  showClearButton,
  children,
  clearSearchProps,
  searchInputProps = {},
}) {
  return (
    <_Component className="div-block-84" tag="div">
      <_Builtin.Block className=" adv-search-lable" tag="div">
        {"Location"}
      </_Builtin.Block>
      <_Builtin.Block className="search-thin-component-copy" tag="div">
        <_Builtin.Block className="div-block-107" tag="div">
          <SearchInputSearchButton />
        </_Builtin.Block>
        <_Builtin.FormTextInput
          className="text-field thin"
          autoFocus={false}
          maxLength={256}
          name="field-4"
          data-name="Field 4"
          placeholder="Search locations 123456"
          type="text"
          disabled={false}
          required={true}
          id="field-4"
          {...searchInputProps}
        />
        <_Builtin.Block className="div-block-108" tag="div">
          <SearchInputClearButton
            showClearButton={showClearButton}
            clearSearchProps={clearSearchProps}
          />
        </_Builtin.Block>
        {showList ? (
          <_Builtin.Block className="div-block-45-copy thin" tag="div">
            <_Builtin.Block className="search-frame-popover thin" tag="div">
              {children ?? (
                <>
                  <AdvSearchSearchInputDropDownItem text="Market Name market name market name market name" />
                  <AdvSearchNoResults />
                  <AdvSearchSearchInputViewAllLocations />
                </>
              )}
            </_Builtin.Block>
          </_Builtin.Block>
        ) : null}
      </_Builtin.Block>
    </_Component>
  );
}
