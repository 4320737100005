"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { SearchInputClearButton } from "./SearchInputClearButton";
import { SearchInputPopoverHeading } from "./SearchInputPopoverHeading";
import { SearchModalResultCommunityAndMarket } from "./SearchModalResultCommunityAndMarket";

export function SearchInputHomePage({
  as: _Component = _Builtin.Block,
  showPopover = true,
  showClear = true,
  children,
  inputProps = {},
  clearSearchProps = {},
}) {
  return (
    <_Component tag="div">
      <_Builtin.FormWrapper className="hero-form_block">
        <_Builtin.FormForm
          className="hero-form_form"
          name="wf-form-Home-Hero-Form"
          data-name="Home Hero Form"
          method="get"
          id="wf-form-Home-Hero-Form"
        >
          <_Builtin.Block className="div-block-103" tag="div">
            <_Builtin.Block className="hero-form_form-wrapper" tag="div">
              <_Builtin.Block className="div-block-47" tag="div">
                <_Builtin.Block className="search-left" tag="div">
                  <_Builtin.Block className="div-block-102" tag="div">
                    <_Builtin.HtmlEmbed
                      className="html-embed-8"
                      value="%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%0A%20%20%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M384%20208A176%20176%200%201%200%2032%20208a176%20176%200%201%200%20352%200zM343.3%20366C307%20397.2%20259.7%20416%20208%20416C93.1%20416%200%20322.9%200%20208S93.1%200%20208%200S416%2093.1%20416%20208c0%2051.7-18.8%2099-50%20135.3L507.3%20484.7c6.2%206.2%206.2%2016.4%200%2022.6s-16.4%206.2-22.6%200L343.3%20366z%22%2F%3E%3C%2Fsvg%3E"
                    />
                  </_Builtin.Block>
                  <_Builtin.FormTextInput
                    className="hero-form_text-field"
                    autoFocus={false}
                    maxLength={256}
                    name="name-3"
                    data-name="Name 3"
                    placeholder="Search by location or community"
                    type="text"
                    disabled={false}
                    required={false}
                    autoComplete="off"
                    tabIndex="0"
                    id="search-input"
                    {...inputProps}
                  />
                  <_Builtin.Block
                    className="search-block-clear-input"
                    tag="div"
                  >
                    <SearchInputClearButton
                      clearSearchProps={clearSearchProps}
                      showClearButton={showClear}
                    />
                  </_Builtin.Block>
                </_Builtin.Block>
                <_Builtin.Block className="search-fake-submit-button" tag="div">
                  <_Builtin.Block className="submit-button-copy" tag="div">
                    <_Builtin.Block tag="div">{"Search"}</_Builtin.Block>
                  </_Builtin.Block>
                </_Builtin.Block>
              </_Builtin.Block>
            </_Builtin.Block>
          </_Builtin.Block>
          {showPopover ? (
            <_Builtin.Block
              className="search-input-popover-home-page-copy-copy"
              tag="div"
            >
              <_Builtin.Block className="search-results-large" tag="div">
                <_Builtin.Block className="search-results-container" tag="div">
                  <_Builtin.Block
                    className="search-input-slot-results"
                    tag="div"
                  >
                    {children ?? (
                      <>
                        <SearchInputPopoverHeading />
                        <SearchModalResultCommunityAndMarket />
                        <SearchModalResultCommunityAndMarket />
                        <SearchModalResultCommunityAndMarket />
                        <SearchModalResultCommunityAndMarket />
                        <SearchModalResultCommunityAndMarket />
                        <SearchInputPopoverHeading text="OUR Communities" />
                        <SearchModalResultCommunityAndMarket showImage={true} />
                        <SearchModalResultCommunityAndMarket showImage={true} />
                        <SearchModalResultCommunityAndMarket showImage={true} />
                        <SearchModalResultCommunityAndMarket showImage={true} />
                        <SearchModalResultCommunityAndMarket showImage={true} />
                        <SearchModalResultCommunityAndMarket showImage={true} />
                      </>
                    )}
                  </_Builtin.Block>
                </_Builtin.Block>
                <_Builtin.Block
                  className="search-results-color-block"
                  tag="div"
                />
              </_Builtin.Block>
            </_Builtin.Block>
          ) : null}
        </_Builtin.FormForm>
        <_Builtin.FormSuccessMessage />
        <_Builtin.FormErrorMessage />
      </_Builtin.FormWrapper>
    </_Component>
  );
}
