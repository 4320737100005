"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { AdvSearchButton } from "./AdvSearchButton";

export function AdvSearchFacetGroup({
  as: _Component = _Builtin.Block,
  text = "Group Name",
  children,
}) {
  return (
    <_Component className=" bedroom-group" tag="div">
      <_Builtin.Block className=" text-44" tag="div">
        {text}
      </_Builtin.Block>
      <_Builtin.Block className="bedroom-option" tag="div">
        {children ?? (
          <>
            <AdvSearchButton text="Studio" />
            <AdvSearchButton text="1" />
            <AdvSearchButton text="2" />
            <AdvSearchButton text="3" />
          </>
        )}
      </_Builtin.Block>
    </_Component>
  );
}
