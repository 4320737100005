"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { SearchInputSearchButton } from "./SearchInputSearchButton";
import { SearchInputClearButton } from "./SearchInputClearButton";
import { ApartmentSearchPopoverItem } from "./ApartmentSearchPopoverItem";

export function ApartmentSearch({
  as: _Component = _Builtin.Block,
  showList = true,
  showClearButton = true,
  children,
  clearSearchProps = {},
  searchInputProps = {},
  filterLinkProps,
  mapSlot,
}) {
  return (
    <_Component className="search-small-component" tag="div">
      <_Builtin.FormWrapper className="form-block">
        <_Builtin.FormForm
          name="email-form-2"
          data-name="Email Form 2"
          method="get"
          id="email-form-2"
        >
          <_Builtin.Block className="seach-frame-container" tag="div">
            <_Builtin.FormTextInput
              className="text-field"
              autoFocus={false}
              maxLength={256}
              name="field"
              data-name="Field"
              placeholder="Example Text"
              type="text"
              disabled={false}
              required={true}
              id="field"
              {...searchInputProps}
            />
            <SearchInputSearchButton />
            <SearchInputClearButton
              clearSearchProps={clearSearchProps}
              showClearButton={showClearButton}
            />
          </_Builtin.Block>
          {showList ? (
            <_Builtin.Block className="div-block-45" tag="div">
              <_Builtin.Block className="search-frame-popover" tag="div">
                {children ?? (
                  <>
                    <ApartmentSearchPopoverItem />
                    <ApartmentSearchPopoverItem />
                  </>
                )}
              </_Builtin.Block>
            </_Builtin.Block>
          ) : null}
        </_Builtin.FormForm>
        <_Builtin.FormSuccessMessage />
        <_Builtin.FormErrorMessage />
      </_Builtin.FormWrapper>
    </_Component>
  );
}
