"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function CardIconCoveredParking({ as: _Component = _Builtin.Block }) {
  return (
    <_Component className="amenity---icon" tag="div" title="EV Charging">
      <_Builtin.HtmlEmbed
        className="html-embed-2"
        value="%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20640%20512%22%20aria-labelledby%3D%22title%22%3E%3E%0A%3Ctitle%20id%3D%22title%22%20lang%3D%22en%22%3EThis%20community%20has%20covered%20parking%3C%2Ftitle%3E%0A%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M343.8%208.8c-14.8-8.4-32.9-8.4-47.6%200L24.1%20164.2C8.8%20173%203.5%20192.5%2012.2%20207.9s28.3%2020.7%2043.7%2011.9L320%2068.9%20584.1%20219.8c15.3%208.8%2034.9%203.4%2043.7-11.9s3.4-34.9-11.9-43.7L343.8%208.8zM249.8%20224H390.2c6.8%200%2012.8%204.3%2015.1%2010.6L424.3%20288H215.7l19.1-53.4c2.3-6.4%208.3-10.6%2015.1-10.6zm-75.3-10.9l-28.3%2079.3C126.1%20300.9%20112%20320.8%20112%20344v40%2064%2032c0%2017.7%2014.3%2032%2032%2032h16c17.7%200%2032-14.3%2032-32V448H448v32c0%2017.7%2014.3%2032%2032%2032h16c17.7%200%2032-14.3%2032-32V448%20384%20344c0-23.2-14.1-43.1-34.2-51.6l-28.3-79.3C454.1%20181.3%20424%20160%20390.2%20160H249.8c-33.8%200-64%2021.3-75.3%2053.1zM192%20344a24%2024%200%201%201%200%2048%2024%2024%200%201%201%200-48zm232%2024a24%2024%200%201%201%2048%200%2024%2024%200%201%201%20-48%200z%22%2F%3E%3C%2Fsvg%3E"
      />
    </_Component>
  );
}
