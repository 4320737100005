"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { Explore } from "./Explore";
import { ButtonPrimaryWhite } from "./ButtonPrimaryWhite";

export function Communities({ as: _Component = _Builtin.Block }) {
  return (
    <_Component className="communities" tag="section">
      <_Builtin.Block className="padding-global" tag="div">
        <_Builtin.Block tag="div">
          <_Builtin.Block className="padding-section-large" tag="div">
            <_Builtin.Block className="communities_header" tag="div">
              <_Builtin.Block className="communities_heading-wrapper" tag="div">
                <_Builtin.Heading tag="h2">
                  {"Our Strong and "}
                  <br />
                  <_Builtin.Span className="text-color-blue">
                    {"Vibrant Communities"}
                  </_Builtin.Span>
                </_Builtin.Heading>
              </_Builtin.Block>
              <_Builtin.Block className="decoration-line" tag="wrapper" />
            </_Builtin.Block>
            <Explore />
            <_Builtin.Block className="margin-top margin-xlarge" tag="div">
              <_Builtin.Block className="button-group is-centered" tag="div">
                <ButtonPrimaryWhite
                  text="Explore Our Communities"
                  link={{
                    href: "/locations/",
                  }}
                />
              </_Builtin.Block>
            </_Builtin.Block>
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}
