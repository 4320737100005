"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function AdvSearchAmenitiesCheckboxItem({
  as: _Component = _Builtin.ListItem,
  text = "Checkbox",
  checkboxProps = {},
  showDisabled = false,
}) {
  return (
    <_Component className="amenities-list-item">
      <_Builtin.FormCheckboxWrapper className="checkbox-field">
        <_Builtin.FormCheckboxInput
          className="amenity-check"
          type="checkbox"
          name="amenity-label"
          data-name="amenity-label"
          required={false}
          checked={false}
          id="amenity-label"
          form={{
            type: "checkbox-input",
            name: "amenity-label",
          }}
          inputType="custom"
          customClassName="w-checkbox-input--inputType-custom"
          {...checkboxProps}
        />
        <_Builtin.FormInlineLabel className="checkbox-label">
          {text}
        </_Builtin.FormInlineLabel>
        {showDisabled ? (
          <_Builtin.Block className="show-disabled-checkbox" tag="div" />
        ) : null}
      </_Builtin.FormCheckboxWrapper>
    </_Component>
  );
}
