"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function AdvSearchRentRange({
  as: _Component = _Builtin.Block,
  textMin = "$",
  textMax = "$10,000",
  rangeSliderSlot,
}) {
  return (
    <_Component className="rheostatcontainer-sc-j148cl-0 hovjsq" tag="div">
      <_Builtin.Block className=" adv-search-lable" tag="div">
        {"Price Range"}
      </_Builtin.Block>
      <_Builtin.Block className="div-block-79" tag="div">
        {rangeSliderSlot ?? (
          <_Builtin.Block className="rheostat rheostat_1" tag="div">
            <_Builtin.Block className="defaultbackground" tag="div" />
            <_Builtin.Block className="handlecontainer" tag="div">
              <_Builtin.Link
                className="defaulthandle_handle"
                button={true}
                block=""
                options={{
                  href: "#",
                }}
              />
              <_Builtin.Link
                className="defaulthandle_handle right"
                button={true}
                block=""
                options={{
                  href: "#",
                }}
              />
            </_Builtin.Block>
            <_Builtin.Block
              className="defaultprogressbar_progressbar"
              tag="div"
            />
          </_Builtin.Block>
        )}
      </_Builtin.Block>
      <_Builtin.Block className="div-block-76" tag="div">
        <_Builtin.Block className="range-slide-price-text" tag="div">
          <_Builtin.Block className="range-slider-text" tag="div">
            {textMin}
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block className="range-slide-price-text" tag="div">
          <_Builtin.Block className="range-slider-text" tag="div">
            {textMax}
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}
