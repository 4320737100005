"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function SearchResults({
  as: _Component = _Builtin.Link,
  textMarket = "Market, State",
  buttonProps = {},
}) {
  return (
    <_Component
      className="search-results-link-block"
      button={false}
      block="inline"
      options={{
        href: "#",
      }}
      {...buttonProps}
    >
      <_Builtin.Block
        className="search-results---submarket-container"
        tag="div"
      >
        <_Builtin.Block className="text-block-3" tag="div">
          {textMarket}
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}
