"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function AdvSearchAmenitiesDropdown({
  as: _Component = _Builtin.Block,
}) {
  return (
    <_Component className="amenities-group" tag="div">
      <_Builtin.Block className=" adv-search-lable" tag="div">
        {"Move-in"}
      </_Builtin.Block>
      <_Builtin.Block className="div-block-69" tag="div">
        <_Builtin.Block className="bedroom-group" tag="div">
          {"Amenities"}
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className="adv-search-drop-down-amenity-popover-copy"
        tag="div"
      />
    </_Component>
  );
}
