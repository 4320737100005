"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _interactions from "./interactions";
import { AdvSearchSearchInput } from "./AdvSearchSearchInput";
import { AdvSearchBedroomGroup } from "./AdvSearchBedroomGroup";
import { AdvSearchAmenitiesDropdown2 } from "./AdvSearchAmenitiesDropdown2";
import { AdvSearchRentRange } from "./AdvSearchRentRange";
import { AdvSearchOpenCloseMapToggle } from "./AdvSearchOpenCloseMapToggle";
import { RedoSearchButton } from "./RedoSearchButton";
import { PropertyCardNoResults } from "./PropertyCardNoResults";

const _interactionsData = JSON.parse(
  '{"events":{"e-97":{"id":"e-97","name":"","animationType":"preset","eventTypeId":"MOUSE_CLICK","action":{"id":"","actionTypeId":"GENERAL_START_ACTION","config":{"delay":0,"easing":"","duration":0,"actionListId":"a-39","affectedElements":{},"playInReverse":false,"autoStopEventId":"e-98"}},"mediaQueries":["main","medium","small","tiny"],"target":{"id":"65ee92366d1cabac2751eed9|e4f8b83c-b3fe-4636-e67f-a0c83bf4bd7b","appliesTo":"ELEMENT","styleBlockIds":[]},"targets":[{"id":"65ee92366d1cabac2751eed9|e4f8b83c-b3fe-4636-e67f-a0c83bf4bd7b","appliesTo":"ELEMENT","styleBlockIds":[]}],"config":{"loop":false,"playInReverse":false,"scrollOffsetValue":null,"scrollOffsetUnit":null,"delay":null,"direction":null,"effectIn":null},"createdOn":1707328603317}},"actionLists":{"a-39":{"id":"a-39","title":"Close Filter","actionItemGroups":[{"actionItems":[{"id":"a-39-n","actionTypeId":"STYLE_OPACITY","config":{"delay":0,"easing":"","duration":200,"target":{"useEventTarget":"PARENT","selector":".modal---position-100-off-page-0","selectorGuids":["a380fd88-6847-6370-dd56-eb80950989f6"]},"value":0,"unit":""}},{"id":"a-39-n-2","actionTypeId":"TRANSFORM_MOVE","config":{"delay":0,"easing":"","duration":200,"target":{"useEventTarget":"PARENT","selector":".modal---position-100-off-page-0","selectorGuids":["a380fd88-6847-6370-dd56-eb80950989f6"]},"xValue":-126,"xUnit":"px","yUnit":"PX","zUnit":"PX"}}]},{"actionItems":[{"id":"a-39-n-3","actionTypeId":"GENERAL_DISPLAY","config":{"delay":0,"easing":"","duration":0,"target":{"useEventTarget":"PARENT","selector":".modal---position-100-off-page-0","selectorGuids":["a380fd88-6847-6370-dd56-eb80950989f6"]},"value":"none"}}]}],"useFirstGroupAsInitialState":false,"createdOn":1706910099212}},"site":{"mediaQueries":[{"key":"main","min":992,"max":10000},{"key":"medium","min":768,"max":991},{"key":"small","min":480,"max":767},{"key":"tiny","min":0,"max":479}]}}'
);

export function AdvSearchComponentsWrapper({
  as: _Component = _Builtin.Block,
  textSearchButton = "Show 11 Apartments",
  searchButtonProps = {},
  children,
  modalChildren,
  mapSlot,
  redoButtonSlot,
  toggleMapButtonProps = {},
  toggleFilterButtonProps = {},
  showDesktopFilterButton = true,
  showAdvSearch = true,
  textMarketName = "search here",
  showNoResults = false,
  resetSearchRuntimeProps = {},
}) {
  _interactions.useInteractions(_interactionsData);

  return (
    <_Component className="frame-210-v2" tag="div">
      <_Builtin.Block className="div-block-80" tag="div">
        <_Builtin.Block className="search-rows" tag="div">
          <_Builtin.Block className="search-components" tag="div">
            {showAdvSearch ? (
              <_Builtin.Block className="adv-search-components-slot" tag="div">
                {children ?? (
                  <>
                    <_Builtin.Block className="location-group" tag="div">
                      <AdvSearchSearchInput
                        showList={false}
                        showClearButton={true}
                      />
                    </_Builtin.Block>
                    <AdvSearchBedroomGroup />
                    <_Builtin.Block className="location-group" tag="div">
                      <AdvSearchAmenitiesDropdown2
                        buttonText="Amenities (2)"
                        showPopover={false}
                        showInline={false}
                        showButton={true}
                      />
                    </_Builtin.Block>
                    <AdvSearchRentRange />
                  </>
                )}
              </_Builtin.Block>
            ) : null}
            {showAdvSearch ? (
              <_Builtin.Block className="div-block-113" tag="div">
                <_Builtin.Block
                  className="text-size-tiny text-style-italic"
                  tag="div"
                >
                  {"Prices subject to change"}
                </_Builtin.Block>
              </_Builtin.Block>
            ) : null}
            {showDesktopFilterButton ? (
              <_Builtin.Block className="seo-search-bar" tag="div">
                <_Builtin.Block
                  className="desktop-show-filter-button"
                  tag="div"
                >
                  <_Builtin.Block
                    className="show-desktop-landscape-copy"
                    tag="div"
                  >
                    <_Builtin.Link
                      className="button is-filter is-small with-text"
                      id="w-node-f3b90877-0431-a2e9-cb20-6b86ee952623-3e5c01a9"
                      button={false}
                      block="inline"
                      options={{
                        href: "#",
                      }}
                      {...toggleFilterButtonProps}
                    >
                      <_Builtin.Block className="text-block-9" tag="div">
                        {textMarketName}
                      </_Builtin.Block>
                      <_Builtin.Block className="div-block-98" tag="div">
                        <_Builtin.HtmlEmbed
                          className="icon-embed-xxsmall-copy"
                          value="%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewbox%3D%220%200%20512%20512%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M0%20416c0%208.8%207.2%2016%2016%2016l65.6%200c7.4%2036.5%2039.7%2064%2078.4%2064s71-27.5%2078.4-64L496%20432c8.8%200%2016-7.2%2016-16s-7.2-16-16-16l-257.6%200c-7.4-36.5-39.7-64-78.4-64s-71%2027.5-78.4%2064L16%20400c-8.8%200-16%207.2-16%2016zm112%200a48%2048%200%201%201%2096%200%2048%2048%200%201%201%20-96%200zM304%20256a48%2048%200%201%201%2096%200%2048%2048%200%201%201%20-96%200zm48-80c-38.7%200-71%2027.5-78.4%2064L16%20240c-8.8%200-16%207.2-16%2016s7.2%2016%2016%2016l257.6%200c7.4%2036.5%2039.7%2064%2078.4%2064s71-27.5%2078.4-64l65.6%200c8.8%200%2016-7.2%2016-16s-7.2-16-16-16l-65.6%200c-7.4-36.5-39.7-64-78.4-64zM192%20144a48%2048%200%201%201%200-96%2048%2048%200%201%201%200%2096zm78.4-64C263%2043.5%20230.7%2016%20192%2016s-71%2027.5-78.4%2064L16%2080C7.2%2080%200%2087.2%200%2096s7.2%2016%2016%2016l97.6%200c7.4%2036.5%2039.7%2064%2078.4%2064s71-27.5%2078.4-64L496%20112c8.8%200%2016-7.2%2016-16s-7.2-16-16-16L270.4%2080z%22%2F%3E%3C%2Fsvg%3E"
                        />
                      </_Builtin.Block>
                    </_Builtin.Link>
                    <_Builtin.Block
                      className="show-map-toggle-container"
                      tag="div"
                    >
                      <AdvSearchOpenCloseMapToggle
                        toggleMapButtonProps={toggleMapButtonProps}
                        showCloseButton={false}
                      />
                    </_Builtin.Block>
                  </_Builtin.Block>
                  <_Builtin.Block className="div-block-113" tag="div">
                    <_Builtin.Block
                      className="text-size-tiny text-style-italic"
                      tag="div"
                    >
                      {"Prices subject to change"}
                    </_Builtin.Block>
                  </_Builtin.Block>
                </_Builtin.Block>
              </_Builtin.Block>
            ) : null}
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block className="modal---position-100-off-page-0" tag="div">
          <_Builtin.Block className="div-block-78" tag="div">
            <_Builtin.Block
              className="modal-container-for-deskop-search"
              tag="div"
            >
              {modalChildren ?? (
                <>
                  <_Builtin.Block className="location-group" tag="div">
                    <AdvSearchSearchInput showList={true} />
                  </_Builtin.Block>
                  <AdvSearchBedroomGroup />
                  <_Builtin.Block className="location-group" tag="div">
                    <AdvSearchAmenitiesDropdown2
                      showPopover={false}
                      showInline={true}
                      showButton={true}
                    />
                  </_Builtin.Block>
                  <AdvSearchRentRange />
                </>
              )}
            </_Builtin.Block>
            <_Builtin.Link
              className="adv-search_close-button"
              button={false}
              block="inline"
              options={{
                href: "#",
              }}
              {...toggleFilterButtonProps}
            >
              <_Builtin.HtmlEmbed
                className="html-embed-4"
                value="%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M256%20512A256%20256%200%201%200%20256%200a256%20256%200%201%200%200%20512zM175%20175c9.4-9.4%2024.6-9.4%2033.9%200l47%2047%2047-47c9.4-9.4%2024.6-9.4%2033.9%200s9.4%2024.6%200%2033.9l-47%2047%2047%2047c9.4%209.4%209.4%2024.6%200%2033.9s-24.6%209.4-33.9%200l-47-47-47%2047c-9.4%209.4-24.6%209.4-33.9%200s-9.4-24.6%200-33.9l47-47-47-47c-9.4-9.4-9.4-24.6%200-33.9z%22%2F%3E%3C%2Fsvg%3E"
              />
              <_Builtin.Block className="text-block-18" tag="div">
                {"close"}
              </_Builtin.Block>
            </_Builtin.Link>
            <_Builtin.Link
              className="desktop-search-button"
              button={true}
              block=""
              options={{
                href: "#",
              }}
              {...searchButtonProps}
            >
              {textSearchButton}
            </_Builtin.Link>
          </_Builtin.Block>
          <_Builtin.Block
            className="modal_close-trigger"
            tag="div"
            {...toggleFilterButtonProps}
          />
        </_Builtin.Block>
        <_Builtin.Block
          className="adv-search-components-modal-dekstop"
          tag="div"
        />
        <_Builtin.Block className="map---position-100-off-page" tag="div">
          <_Builtin.Block className="map-slot-container" tag="div">
            {mapSlot ?? (
              <_Builtin.Image
                className="image-15"
                loading="lazy"
                width="auto"
                height="auto"
                alt=""
                src="https://uploads-ssl.webflow.com/656bce4e0dae8369c9788229/6572534a87098e4ee3860ed5_map.png"
              />
            )}
          </_Builtin.Block>
          <_Builtin.Block className="div-block-92" tag="div">
            <_Builtin.Block
              className="map-redo-search-button-container"
              tag="div"
            >
              {redoButtonSlot ?? <RedoSearchButton showShowDisabled={false} />}
            </_Builtin.Block>
            <_Builtin.Block
              className="map-openclose-component-container"
              tag="div"
            >
              <AdvSearchOpenCloseMapToggle
                toggleMapButtonProps={toggleMapButtonProps}
                showCloseButton={true}
              />
            </_Builtin.Block>
            {showNoResults ? (
              <_Builtin.Block className="map-no-results-container" tag="div">
                <_Builtin.Block
                  className="map-no-results-component-container"
                  tag="div"
                >
                  <PropertyCardNoResults
                    resetSearchRuntimeProps={resetSearchRuntimeProps}
                  />
                </_Builtin.Block>
              </_Builtin.Block>
            ) : null}
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}
