"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function SearchModalResultItem({
  as: _Component = _Builtin.Link,
  text = "Market Name, State",
}) {
  return (
    <_Component
      className="search-modal-result"
      button={true}
      block=""
      options={{
        href: "#",
      }}
    >
      {text}
    </_Component>
  );
}
