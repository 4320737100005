"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function AdvSearchNoResults({ as: _Component = _Builtin.Block }) {
  return (
    <_Component className="adv-search-no-results-container" tag="div">
      <_Builtin.Block className="adv-search-no-results-text" tag="div">
        {"We could not find any locations matching your search in this area."}
        <br />
      </_Builtin.Block>
    </_Component>
  );
}
